// VPP Common
@import '../../../../styles/common';

// Andes Shared for VIP CORE
@import '../core/vip.core.andes';

// Andes styles for VIP CORE Mobile
@import '~@andes/bottom-sheet/index';
@import '~@andes/accordion/index';

// Recos FE
@import '~@recommendations-fe/carousel-free/src/styles/recommendations-fe-carousel-free.mobile';
@import '~@recommendations-fe/combos/src/styles/mobile';
@import '~@recommendations-fe/list/src/styles/recommendations-fe-list.mobile';
@import '~@recommendations-fe/card/src/styles/recommendations-fe-card-horizontal.mobile';
@import '~@recommendations-fe/card/src/styles/recommendations-fe-card-vertical.mobile';
@import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-horizontal--for-carousel-free';
@import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-horizontal--for-list-double';
@import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-vertical--for-carousel-free';
@import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-vertical--for-list-grid';
@import '../../../../components/recommendations/styles/polycard-overrides.mobile';

// Seo Frontend Components
@import '~@seo-frontend-components/extended-menu-mobile/index';

// Polycard
@import '~@polycard/phrase/build';
@import '~@polycard/card/build';

// UI Comps web
@import '~@ui-autoparts-web/compats/src/styles/mobile';

// UI Reviews
@import '~@ui-reviews/ui-reviews-capability/src/styles/reviews.mobile';

// CPG Nordic
@import '~cpg-nordic/src/styles/cpg.mobile';

// EShops Components Library
@import '~eshops-components-library/src/components/ProfileHeader/lib/mobile';
@import '~eshops-components-library/src/components/BottomSheet/index';

// VIP CORE Common
@import '../core/vip.core';

// VIP Internal imports
@import '../../../../components/header/styles/header.mobile';
@import '../../../../components/gallery/styles/gallery.mobile';
@import '../../../../components/price/styles/price.mshops.mobile';
@import '../../../../components/generic-summary/generic-summary.mobile';
@import '../../../../components/bookmark/styles/bookmark.mobile';
@import '../../../../components/share/share.mobile';
@import '../../../../components/share/share-content.mobile';
@import '../../../../components/action-modal/styles/action-modal.mobile';
@import '../../../../components/backdrop-modal/styles/backdrop-modal';
@import '../../../../components/grouped-share-bookmark/grouped-share-bookmark';
@import '../../../../components/gift-registry/styles/gift-registry.mobile';
@import '../../../../components/review-summary/styles/review-summary.mobile';
@import '../../../../components/action-collapsable/styles/action-collapsable.mobile';
@import '../../../../components/reviews/styles/reviews.mobile';
@import '../../../../components/variations/styles/variations.mshops.mobile';
@import '../../../../components/buybox/styles/buybox.mobile';
@import '../../../../components/buybox/actions/styles/action.mobile';
@import '../../../../components/buybox/actions/styles/action-buybox.mobile';
@import '../../../../components/benefits/styles/benefits.mobile';
@import '../../../../components/specs/specs.mobile';
@import '../../../../components/action-row/styles/action-row';
@import '../../../../components/action-see-more/styles/action-see-more';
@import '../../../../components/list/list';
@import '../../../../components/features/styles/features';
@import '../../../../components/payment-methods/styles/payment-methods.mobile';
@import '../../../../components/description/styles/description.mobile';
@import '../../../../components/seller-info-label/styles/seller-info.mobile';
@import '../../../../components/seller-info/styles/seller-info';
@import '../../../../components/seller-reputation-info/styles/seller-reputation';
@import '../../../../components/seller-reputation-info/styles/seller-reputation-mobile';
@import '../../../../components/seller-data/styles/seller-data';
@import '../../../../components/seller-data/styles/banner';
@import '../../../../components/seller-data/styles/header';
@import '../../../../components/seller-data/styles/status';
@import '../../../../components/seller-data/styles/footer';
@import '../../../../components/seller-data/styles/recommendations';
@import '../../../../components/questions/styles/questions.mobile';
@import '../../../../components/qadb/styles/qadb.mobile';
@import '../../../../components/box-component/styles/box-component.mobile';
@import '../../../../components/box-component/styles/box.mobile';
@import '../../../../components/component-list/component-list';
@import '../../../../components/related-searches/styles/related-searches.mobile';
@import '../../../../components/rating-levels/styles/rating-levels.mobile';
@import '../../../../components/apparel-size-specs/styles/apparel-size-specs.mobile';
@import '../../../../components/apparel-size-specs-preview/styles/apparel-size-specs-preview.mobile';
@import '../../../../components/card-tooltip/styles/card-tooltip.mobile';
@import '../../../../components/onboarding/styles/onboarding.mobile';
@import '../../../../components/tooltip/components/styles/information-tooltip-content';
@import '../../../../components/denounce/styles/denounce.mobile';
@import '../../../../components/breadcrumb/styles/breadcrumb.mobile';
@import '../../../../components/advertising/styles/advertising.mobile';
@import '../../../../components/promotions/styles/promotions.mobile';
@import '../../../../components/cpg/styles/summary.mobile';
@import '../../../../components/highlighted-specs/styles/highlighted-specs.mobile';
@import '../../../../components/specs/striped-specs';
@import '../../../../components/data-display/discrete-bar/styles/discrete-bar';
@import '../../../../components/data-display/progress-bar/styles/progress-bar';
@import '../../../../components/merch-ecosystem/styles/merch-ecosystem';
@import '../../../../components/merch-ecosystem/styles/merch-ecosystem.mobile';
@import '../../../../components/merch-ecosystem/styles/merch-ecosystem.vip.mobile';
@import '../../../../components/buybox/cart-actions/styles/cart-actions.mobile';
@import '../../../../components/moq-information/styles/moq-information.mobile';
@import '../../../../components/stock-information/styles/stock-information.mobile';
@import '../../../../components/tooltip-legal/styles/tooltip-legal.mobile';
@import '../../../../components/compats-widget/styles/compats-widget.main.mobile';
@import '../../../../components/short-description-message/styles/short-description-message';
@import '../../../../components/official-stores/styles/carousel.mobile';
@import '../../../../components/on-demand-iframe/styles/on-demand-iframe.mobile';
@import '../../../../components/integrations/styles/integrations.mobile';
@import '../../../../components/coupons/styles/coupons.mobile';
@import '../../../../components/coupons/styles/coupons-vip.mobile';
@import '../../../../components/coupons-awareness/styles/coupons-awareness.mobile';
@import '../../../../components/header-top-brand/styles/header.mobile';
@import '../../../../components/header-brand-logo/styles/header-brand-logo.mobile';
@import '../../../../components/trade-in/styles/trade-in.mobile';
@import '../../../../components/preload-iframe/styles/index.mobile';
@import '../../../../components/eshops/styles/eshops.mobile';
@import '../../../../components/buybox/banner/styles/banner-vip.mobile';
@import '../../../../components/advertising-adn/styles/billboard-context.mobile';
@import '../../../../components/installation-service/styles/installation-widget.mobile';
@import '../../../../components/extended-menu/styles/extended-menu.mobile';

// Recos overrides Polycard
@import '~@recommendations-fe/commons/src/styles/overrides/polycard_card-list';
@import '~@recommendations-fe/commons/src/styles/overrides/polycard_card-grid';
@import '~@recommendations-fe/carousel-free/src/styles/overrides/polycard_carousel-free-horizontal';
@import '~@recommendations-fe/carousel-free/src/styles/overrides/polycard_carousel-free-default';
@import '~@recommendations-fe/list/src/styles/overrides/polycard_list-double';
@import '~@recommendations-fe/list/src/styles/overrides/polycard_list-grid';

// Recos FE override for shops themes
@import '../../../../components/recommendations/styles/shops-themes-overrides.mobile';

@mixin returns-notification-margin($marginTop) {
  margin: $marginTop $andes-spacing-16 0;
}

html,
body {
  height: auto;
  min-height: 100%;
}

.#{$vip-core-prefix} {
  background-color: $andes-white;

  .ui-pdp-container--bottom {
    background-color: $andes-bg-primary;
  }

  .ui-pdp-container__row {
    &--highlighted-specs-attrs,
    &--highlighted-specs-attrs-swap,
    &--highlighted-specs-attrs-third-swap {
      border-top: 1px solid $andes-gray-100;
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &--gallery,
    &--gallery-octopus {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &--variations {
      margin-top: 16px;
    }

    &--payment-summary {
      margin-top: 20px;
      margin-bottom: 16px;
    }

    &--cbt-summary:not(:empty) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &--cbt-taxes-summary:not(:empty) {
      margin-bottom: 20px;
    }

    &--cbt-taxes-fc-us-summary:not(:empty) {
      margin-top: 5px;
    }

    &--meliplus-label-summary {
      margin-top: $andes-spacing-24;
      margin-bottom: $andes-spacing-12;
    }

    &--shipping-summary,
    &--second-shipping-summary,
    &--meliplus-summary,
    &--upselling-meliplus-summary,
    &--returns-summary,
    &--pick-up-summary {
      margin-top: $andes-spacing-12;
    }

    &--promotions {
      margin: $andes-spacing-16 0;
      margin-top: $andes-spacing-24;
      padding: 0 $andes-spacing-16;
    }

    &--variations:not(:empty) {
      margin-top: $andes-spacing-24;
    }

    &--seller {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &--stock-and-full {
      margin-left: $andes-spacing-16;
      margin-right: $andes-spacing-16;
      margin-top: $andes-spacing-24;

      .ui-pdp-container__row--fulfillment-information {
        margin-top: -4px;

        .ui-pdp-icon--full {
          top: 3px;
          width: 50px;
          height: 15px;
        }

        .ui-pdp-icon--full-super {
          width: fit-content;
          height: fit-content;
        }

        .ui-pdp-promotions-pill-label--with-icon {
          padding: 0;
        }
      }
    }

    &--top-available-quantity-summary {
      margin: $andes-spacing-24 $andes-spacing-20 -8px;

      & svg {
        width: 15px;
        height: 15px;
        top: 2px;
        margin-right: $andes-spacing-4;
      }

      & .ui-pdp-action-modal__link {
        font-weight: 600;
      }
    }

    &--available-quantity {
      margin-top: 16px;
      margin-bottom: 8px;
    }

    &--main-actions {
      margin-top: 26px;
      margin-bottom: 26px;
    }

    &--paypal-tooltip:not(:empty) {
      margin-top: $andes-spacing-16;
    }

    &--cart-actions {
      margin-bottom: $andes-spacing-24;
    }

    &--buy-benefits {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &--grouped-share-bookmark {
      margin: 16px 16px 32px;
    }

    &--gift-registry {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    &--breadcrumb {
      padding: 16px 16px 32px;
    }

    &-container__row--advertising {
      padding: 32px 16px;
    }
  }

  .ui-pdp-buybox__quantity {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .#{$pdp-prefix}-moq-information {
    padding: 0 $andes-spacing-16;
  }

  .#{$pdp-prefix} {
    &-description {
      border-top: 1px solid $andes-gray-100;
      padding: 40px 16px;
    }

    &-specs-mobile {
      margin-bottom: 0;
      border-top: 1px solid $andes-gray-100;
      padding: 40px 16px;
      border-bottom: 0;
    }

    &-reviews {
      border-top: 1px solid $andes-gray-100;
      padding: $andes-spacing-40 0;
    }

    &-questions {
      border-top: 1px solid $andes-gray-100;
      padding: 40px 16px;

      &__content {
        margin-top: 16px;
      }

      &__button {
        margin-top: 18px;
      }
    }

    &-carousel-cheaper {
      background: $andes-bg-secondary;
      padding: 16px 0 20px;
    }

    &-carousel-not-active {
      background: $andes-bg-secondary;
      padding-bottom: 24px;
    }

    &-carousel-grid-bottom {
      margin: 16px 16px 0;
    }

    &-vis-carousel-b2c {
      border-top: 1px solid $andes-gray-100;
      padding: $andes-spacing-40 0;
    }
  }

  .ui-vpp-apparel-size {
    margin-top: $andes-spacing-12;

    /* stylelint-disable-next-line selector-class-pattern */
    &.__outside-variations {
      margin-top: 0;
    }
  }

  .ui-vpp-message {
    width: 100%;
    margin: 16px;
  }

  .ui-pdp-seller {
    padding: 0 16px;

    &__header {
      margin-bottom: 0;

      &__title {
        margin-top: 0;
      }
    }

    &__header--margin {
      margin-bottom: 18px;
    }
  }

  .#{$pdp-prefix}-benefits {
    .#{$pdp-prefix}-media__figure {
      height: 16px;
    }
  }

  .ui-vpp-advertising {
    width: 100%;
  }

  .#{$pdp-prefix}-container__row--item-status-message {
    background-color: $andes-bg-secondary;
  }

  .container-advertising.ui-fullscreen-ad-vip.loaded {
    padding: 16px;
  }

  .ui-pdp-container__row--returns-notification-bottom,
  .ui-pdp-container__row--returns-notification-up {
    display: block;
  }

  .ui-pdp-container__row--returns-notification-bottom .ui-pdp-message {
    @include returns-notification-margin(0);
  }

  .ui-pdp-container__row--returns-notification-up .ui-pdp-message {
    @include returns-notification-margin(5px);
  }
}

.ui-vip-questions {
  .ui-pdp-questions {
    padding: $andes-spacing-12 0;
    border: none;

    &__title {
      font-size: $font-size-24;
      font-weight: $font-weight-semibold;
    }
  }
}

.ui-pdp-qadb {
  border-top: 1px solid $andes-gray-100;
  padding: 40px 16px;
}

.ui-recommendations-combos {
  border-top: 1px solid $pdp-gray-100;
  padding-top: $andes-spacing-40;
}

.shops__pills {
  font-size: $font-size-16;
}

.ui-vpp-grouped-share-bookmark-wishlist {
  margin-bottom: 8px;
}

.ui-vpp-gift-registry__container.ui-vpp-gift-registry--mobile {
  margin-bottom: -16px;
  margin-top: $andes-spacing-24;
}

.ui-pdp-action-modal__modal.ui-pdp-iframe-modal.ui-pdp-fc-us-taxes {
  width: 568px;
  height: 386px;

  .andes-modal__header {
    padding: $andes-spacing-32 $andes-spacing-32 0;
  }

  .andes-modal__scroll {
    overflow: hidden;
    border-radius: $border-radius-6;
  }
}

.ui-pdp-iframe-modal-top-quantity-summary {
  .andes-modal--full .andes-modal__content {
    padding: 0;
  }

  .andes-modal__close-button path {
    stroke: $andes-gray-250 !important;
  }
}

.ui-review-capability-vpp {
  border-top: 1px solid $pdp-gray-100;
  width: 100%;

  .andes-button[type='submit'] {
    width: 100%;
    margin: $andes-spacing-20 0;
  }
}
