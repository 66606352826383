@import './price.mobile';

.ui-pdp {
  .ui-pdp-price__volume-tags {
    padding-left: 0;
  }
}

.ui-pdp-price__volume-tags {
  padding: 6px $andes-spacing-16 14px;

  &--shops {
    box-shadow: unset;
    // stylelint-disable-next-line custom-property-pattern
    font-family: var(--secondaryFontVar) !important;
  }

  .ui-pdp-price__volume-tags--pill {
    font-size: $font-size-16;
  }

  .ui-pdp-price__volume-tags--subtitle {
    font-size: $font-size-16;
    margin-left: $andes-spacing-4;
  }

  .ui-pdp-price__volume-tags--highlight {
    // stylelint-disable-next-line custom-property-pattern
    color: var(--informativeColorVar, $andes-blue-500);
  }

  // stylelint-disable-next-line selector-class-pattern
  .ui-vpp-tag-limited_element,
  .ui-pdp-price__volume-tags--subtitle,
  .ui-pdp-price__volume-tags--pill-container {
    display: inline;
  }
}
